
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: clamp(1.3rem, 0.892vw, 1.5rem);
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    top: -1em;
    right: -2em;
    bottom: -1em;
    left: -1em;
  }

  &:hover,
  &:focus {
    .link__label {
      flex: 1;
    }

    .link__svg {
      transform: translateX(1rem);
    }
  }

  &__label {
    flex: 0;
    overflow: hidden;
    transition: flex $easing $transition-duration;
  }

  &__label-inner {
    white-space: nowrap;
  }

  &__svg {
    width: clamp(5rem, 4.88vw, 8.21rem);
    height: clamp(2.3rem, 2.083vw, 3.49rem);
    fill: currentColor;
    transition: transform $easing $transition-duration,
      fill $easing $transition-duration;
  }
}
