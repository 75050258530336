
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































.section {
  background-color: $c-surface-light-accent;
}

.cta {
  display: block;

  &__svg {
    display: block;
    width: 8.12rem;
    height: 3.49rem;
  }
}

::v-deep {
  .ref {
    --progress: 0;

    @media (prefers-reduced-motion) {
      --progress: 1;
    }

    position: relative;
    display: block;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #c7c7c7;
      transform-origin: 0 0;
      transform: scaleX(var(--progress));
    }

    @include mq(s) {
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      border-top-color: currentColor;
    }
  }

  .ref__title {
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 600;
  }

  .ref__details {
    opacity: 0.6;
  }

  .ref__thumbnail {
    position: absolute;
    top: 0;
    right: 110%;
    width: 30rem;
    height: 20rem;
    clip-path: inset(0% 100% 0% 0%);
  }

  .ref__title__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;

    @include mq(s) {
      flex-direction: row;
    }
  }
}
